<template>
  <div 
    class="temperature-control d-flex flex-column align-center justify-center"
    :class="{ 'disabled': disabled }"
  >
    <span class="text-overline text--secondary ma-4">Temperatura</span>
    <!-- <span class="text-body-2 text--secondary ma-4">Selecione início e fim para determinar </span> -->
    <v-range-slider
      v-model="controller.value"
      min="-50"
      max="50"
      thumb-label="always"
      :prepend-icon="icons.snow"
      :append-icon="icons.fire"
      :hint="hint"
      :color="disabled ? 'grey' : 'primary'"
      class="temperature-slider ma-4 flex-grow-0"
      style="width: 50%"
      @change="update"
    >
      <template v-slot:thumb-label="props">
        {{ props.value }}°

        <!-- <v-icon dark>
          {{ season(props.value) }}
        </v-icon> -->
      </template>
    </v-range-slider>
  </div>
</template>

<style lang="scss">

.temperature-control.disabled {
  opacity: .8;
  pointer-events: none;
}

</style>

<script>

  import { mdiSnowflake, mdiFire } from '@mdi/js';

  export default {
    props: {
      selected: {
        type: [String, Number],
        default: 0
      },
      hint: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    
    data: () => ({
      icons: {
        snow: mdiSnowflake, 
        fire: mdiFire
      },
      controller: {
        value: [-50,50]
      }
    }),

    watch: {
      selected: {
        immediate: true,
        handler (selected) {
          this.controller.value = this.decode(selected);
        }
      }
    },

    methods: {
      decode (data) {
        let range = [-50, 50];
        if (!_.isEmpty(data)) {
          const groupBy = /\(([^)]+)\)/g;
          const groups = _.map([...data.matchAll(groupBy)], g => {
            const degrees = _.map(_.split(g[1], '|'), d => parseInt(d)).sort((a, b) => a - b);
            return [_.first(degrees), _.last(degrees)+1];
          })
          range = _.last(groups)
        }
        console.log(range);
        return range;
      },

      encode (range) {
        let data = '';
        if (!_.isEqual(range, [-50,50])) {
          data = '^(' + _.join(_.range(_.first(range), _.last(range)), '|') + ')$';
        }
        return data;
      },

      update () {
        const selection = this.encode(this.controller.value);
        console.log('update temperature', selection);
        this.$emit('update', selection);
      },

      hasCaption (option) {
        return option.hasOwnProperty('caption');
      }

    },
  }
</script>